import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SlideOver, {
  classSlideCloseAnimation,
  sliderAnimationDurationInMs,
} from "../../../common/components/SlideOver/SlideOver";
import {
  closeSlider,
  getCurrentModalStateSlider,
  setAnimationClassState,
} from "../../../common/state/slice/modal/modalSlice";
import { RootState } from "../../../common/state/store";
import { setAlert } from "../../alert/state/alertsSlice";
import {
  addMediaToDatabase,
  fetchBlockadeLabsImageModels,
  generateVoiceOver,
  getBlockadeLabsModels,
  getCurrentTagsOrganizationId,
  getTtsPreview,
  resetTtsPreview,
  setCurrentMediaGallery,
  setOpenedSlider,
  setUploadNewTagsInSuggestions,
} from "../state/gallerySlice";
import { LoadingState, loadingSelector } from "../../../common/state/slice/appStateSlice";
import { Dropdown, Input } from "src/common/components/Input";
import { IsLoading } from "src/common/components/AppState/IsLoading";
import TextArea from "src/common/components/Input/TextArea";
import { useAppDispatch } from "src/common/state/hooks";
import { IMedia } from "src/model/unityObject";
import InformationFrame from "src/common/components/InfomationFrame/InformationFrame";
import { getCurrentUser } from "../../profile/state/profileSlice";
import { Tabs, Tab, Box } from "@mui/material";
import { getSubscriptionFeature } from "src/features/subscription/state/subscriptionSlice";
import { GenerateBlockadeLabsImage } from "./GenerateBlockadeLabsImage";

export const GENERATE_MEDIA_PANEL_COMPONENT = "generateMediaPanel";
export const FETCH_AI_MODELS_FROM_BLOCKADE_LABS = "fetchAiModelsFromBlockadeLabs";
export const GENERATE_MEDIA_SLIDEOVER_COMPONENT = "generateMediaPanelSlideover";

export const GenerateMediaPanel = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isFormEnabled, setIsFormEnabled] = useState(true);
  const tagsOrganization = useSelector(getCurrentTagsOrganizationId);
  const openSliderId = useSelector(getCurrentModalStateSlider);
  const ttsPreview = useSelector(getTtsPreview);

  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const { loading, blockadeLabsFeature } = useSelector((state: RootState) => {
    return {
      loading: loadingSelector(state, GENERATE_MEDIA_PANEL_COMPONENT),
      blockadeLabsFeature: getSubscriptionFeature(state, "blockade-labs-integration"),
    };
  });

  const isAllowedToUseBlockadeLabsIntegration = blockadeLabsFeature?.IsEnabled;
  const currentUser = useSelector(getCurrentUser);

  const [tagEditorKey, setTagEditorKey] = useState(0);
  const [mediaNameToEdit, setMediaNameToEdit] = useState<string>("");
  const [voice, setVoice] = useState("Lea");
  const [voiceOptions, setVoiceOptions] = useState([
    { value: "Lea", optionText: t("general.female") },
    { value: "Remi", optionText: t("general.male") },
  ]);
  const [language, setLanguage] = useState("fr-FR");

  const blockadeLabsModels = useSelector(getBlockadeLabsModels);

  const MAX_CHAR_NB = 1500;

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm<{ textToGenerate: string }>({
    defaultValues: {
      textToGenerate: "",
    },
  });

  const closeSliderAnimation = () => {
    setTimeout(() => {
      dispatch(closeSlider(GENERATE_MEDIA_PANEL_COMPONENT));
      dispatch(setOpenedSlider(""));
    }, sliderAnimationDurationInMs);

    setTimeout(() => {
      dispatch(setAnimationClassState(classSlideCloseAnimation));
    }, 1);
  };

  const tts = async () => {
    // In case we want to let the user change the file's name
    setIsFormEnabled(false);
    await dispatch(
      generateVoiceOver({
        voice,
        language,
        text: getValues("textToGenerate"),
        name: mediaNameToEdit,
        componentId: GENERATE_MEDIA_PANEL_COMPONENT,
      }),
    ).then((res: any) => {
      dispatch(
        setUploadNewTagsInSuggestions({
          newTags: res.payload.tags,
          oldTags: tagsOrganization,
        }),
      );

      setIsAlertVisible(true);

      return res.payload;
    });
  };

  const getErrorText = () => {
    switch (errors.textToGenerate?.type) {
      case "maxLength":
        return t("pages.gallery.GenerateMediaPanel.maxCharacters");
      case "isNotEmpty":
        return t("pages.gallery.generateVoicePanel.emptyText");
      default:
        return "";
    }
  };

  const resetTts = () => {
    setIsFormEnabled(true);
    reset({
      textToGenerate: "",
    });
    setMediaNameToEdit("");
    dispatch(resetTtsPreview());
    setTagEditorKey(tagEditorKey + 1);

    if (currentUser?.preferred_language.name === "French") {
      setVoice("Lea");
      setLanguage("fr-FR");
      setVoiceOptions([
        { value: "Lea", optionText: `${t("general.female")} (Lea)` },
        { value: "Remi", optionText: `${t("general.male")} (Remi)` },
      ]);
    } else if (currentUser?.preferred_language.name === "English") {
      setVoice("Kendra");
      setLanguage("en-US");
      setVoiceOptions([
        { value: "Kendra", optionText: `${t("general.female")} (Kendra)` },
        { value: "Stephen", optionText: `${t("general.male")} (Stephen)` },
      ]);
    }
  };

  useEffect(() => {
    resetTts();
  }, [openSliderId]);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (isAllowedToUseBlockadeLabsIntegration) {
      if (blockadeLabsModels.length === 0) {
        dispatch(fetchBlockadeLabsImageModels({ componentId: FETCH_AI_MODELS_FROM_BLOCKADE_LABS }));
      }
    }
  }, [isAllowedToUseBlockadeLabsIntegration, blockadeLabsModels]);

  return (
    <SlideOver large={false} id={GENERATE_MEDIA_SLIDEOVER_COMPONENT}>
      <div className="flex flex-col px-6 pt-8 pb-3 min-h-screen bg-white shadow-md relative">
        {isAllowedToUseBlockadeLabsIntegration && (
          <Tabs value={selectedTab} onChange={handleTabChange}>
            <Tab label={t("pages.gallery.generateMediaPanel.voiceOver")} />
            <Tab label={t("pages.gallery.generateMediaPanel.360Image")} />
          </Tabs>
        )}
        <Box>
          {selectedTab === 0 && (
            <div>
              {isAlertVisible && (
                <InformationFrame
                  className="w-[90%] px-6 py-6 mx-auto absolute top-[-20px] transition-opacity z-[1000]"
                  style="success"
                  onClose={() => setIsAlertVisible(false)}
                  withTimeout
                  showCloseButton
                >
                  <div>{t("pages.gallery.generateVoicePanel.success")}</div>
                </InformationFrame>
              )}
              <>
                <h3 className={"font-medium text-lg mb-6 mt-2"}>
                  {t("pages.gallery.generateVoicePanel.title")}
                </h3>
                <div>
                  <form onSubmit={handleSubmit(tts)}>
                    <div className="block font-medium text-gray-700 ">{t("general.fileName")}</div>
                    <Input
                      className="mb-4"
                      name="name"
                      type="text"
                      maxLength={30}
                      value={mediaNameToEdit}
                      placeholder={t("general.fileName")}
                      onChange={(e) => {
                        setMediaNameToEdit(e.target.value);
                      }}
                      required
                      disabled={Boolean(ttsPreview) || loading === LoadingState.Loading}
                    />
                    {!ttsPreview && (
                      <Dropdown
                        options={[
                          {
                            value: "fr-FR",
                            optionText: t("pages.gallery.generateVoicePanel.languages.french"),
                          },
                          {
                            value: "en-US",
                            optionText: t("pages.gallery.generateVoicePanel.languages.english"),
                          },
                        ]}
                        label={t("general.language")}
                        labelFontClassName={"text-md"}
                        onChangeCb={(e: any) => {
                          setLanguage(e.target.value);

                          if (e.target.value === "fr-FR") {
                            setVoice("Lea");
                            setVoiceOptions([
                              { value: "Lea", optionText: `${t("general.female")} (Lea)` },
                              { value: "Remi", optionText: `${t("general.male")} (Remi)` },
                            ]);
                          } else if (e.target.value === "en-US") {
                            setVoice("Kendra");
                            setVoiceOptions([
                              { value: "Kendra", optionText: `${t("general.female")} (Kendra)` },
                              { value: "Stephen", optionText: `${t("general.male")} (Stephen)` },
                            ]);
                          }
                        }}
                        asDefaultValue
                        value={language}
                        defaultValueIndex={0}
                      />
                    )}
                    {!ttsPreview && (
                      <Dropdown
                        options={voiceOptions}
                        label={t("pages.gallery.generateVoicePanel.voice")}
                        labelFontClassName={"text-md mt-2"}
                        onChangeCb={(e: any) => {
                          setVoice(e.target.value);
                        }}
                        asDefaultValue
                        defaultValueIndex={0}
                      />
                    )}
                    <IsLoading
                      componentId={GENERATE_MEDIA_PANEL_COMPONENT}
                      spinnerPlaceholder
                      showSuccess={false}
                      spinnerStyle={{ marginTop: "4vh" }}
                    >
                      {!ttsPreview ? (
                        <>
                          <div className="block font-medium text-gray-700 mt-4">
                            {t("general.textVoice")}
                          </div>
                          <TextArea
                            isResizable={false}
                            className="bg-white w-full h-64 mt-4"
                            placeholder={t("pages.gallery.generateVoicePanel.textInputPlaceholder")}
                            registerFct={() =>
                              register("textToGenerate", {
                                maxLength: MAX_CHAR_NB,
                                validate: {
                                  isNotEmpty: (value) => value.trim().length > 0,
                                },
                              })
                            }
                          />

                          {errors.textToGenerate && (
                            <span className="text-red-600 text-sm mt-2">{getErrorText()}</span>
                          )}
                        </>
                      ) : (
                        <div className="mt-4">
                          <audio id="tts-preview" className="w-full" controls autoPlay>
                            <source src={ttsPreview.s3Url} />
                          </audio>
                        </div>
                      )}
                      <div className="mt-5" key={JSON.stringify(ttsPreview)}>
                        {!ttsPreview ? ( // todo: add condition "if !tts loading"
                          <button
                            disabled={!isFormEnabled}
                            className={`btn-primary-fill mr-3 mt-2 ${
                              !isFormEnabled ? "cursor-not-allowed" : ""
                            }`}
                            type="submit"
                          >
                            {t("general.generate")}
                          </button>
                        ) : (
                          <div className="flex items-center justify-end">
                            <button
                              type="button"
                              className={"btn-primary-outline mr-3 mt-2"}
                              onClick={() => {
                                resetTts();
                              }}
                            >
                              {t("general.cancel")}
                            </button>
                            <button
                              type="button"
                              className={"btn-primary-fill mr-3 mt-2"}
                              onClick={() => {
                                if (ttsPreview) {
                                  addMediaToDatabase({
                                    name: ttsPreview?.name,
                                    s3Url: ttsPreview?.s3Url.split("?")[0],
                                    mimeType: ttsPreview?.mimeType,
                                    media360tag: ttsPreview?.media360tag,
                                    fileSizeInByte: ttsPreview?.fileSizeInByte,
                                    fileName: ttsPreview?.fileName,
                                    MD5: ttsPreview?.MD5,
                                    dispatch,
                                    uploadId: "",
                                    tts: true,
                                  })
                                    .then((res: IMedia) => {
                                      dispatch(setCurrentMediaGallery(res.id));
                                      dispatch(resetTtsPreview());
                                      dispatch(
                                        setAlert({
                                          type: "success",
                                          msg: t("pages.gallery.generateVoicePanel.uploadSuccess"),
                                        }),
                                      );

                                      closeSliderAnimation();
                                    })
                                    .catch((err) => {
                                      console.error(err);
                                    });
                                }
                              }}
                            >
                              {t("general.save")}
                            </button>
                          </div>
                        )}
                      </div>
                    </IsLoading>
                  </form>
                </div>
              </>
            </div>
          )}
          {selectedTab === 1 && (
            <GenerateBlockadeLabsImage
              models={blockadeLabsModels}
              closeSliderAnimation={closeSliderAnimation}
            />
          )}
        </Box>
      </div>
    </SlideOver>
  );
};
