import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ResponsiveContainer, XAxis, YAxis, Bar, CartesianGrid, BarChart, Tooltip } from "recharts";
import {
  ISessionsByProjectGroup,
  ISessionsByProjectGroupWithColor,
  ISessionsByProjectWithColor,
  sessionsByProjectGroupSelector,
} from "../../state/statsSlice";
import { ISession } from "../../../../model/model";
import "./Charts.scss";

interface Props {
  noSession: boolean;
  isProject?: boolean;
  id?: number;
  userId?: number;
}

const BarChartComp = ({ noSession, isProject = true, id, userId = 0 }: Props) => {
  const { t } = useTranslation();

  const [data, setData] = useState([{}]);

  const sessionsByProjectGroupWithColor = useSelector(sessionsByProjectGroupSelector);

  // Create array of 12 latest months
  const monthList = [
    t("pages.statistics.months.january"),
    t("pages.statistics.months.february"),
    t("pages.statistics.months.march"),
    t("pages.statistics.months.april"),
    t("pages.statistics.months.may"),
    t("pages.statistics.months.june"),
    t("pages.statistics.months.july"),
    t("pages.statistics.months.august"),
    t("pages.statistics.months.september"),
    t("pages.statistics.months.october"),
    t("pages.statistics.months.november"),
    t("pages.statistics.months.december"),
  ];

  const now = new Date();
  const lastTwelveMonths: any[] = [];

  for (let i = 0; i < 12; i++) {
    const formattedDate = new Date(now.getFullYear(), now.getMonth() - i, 1);
    const year = formattedDate.getFullYear();
    const month = formattedDate.getMonth();
    const toDisplay = `${month + 1}/${String(year).slice(-2)}`;
    lastTwelveMonths.unshift({
      monthName: monthList[month],
      toDisplay,
      month: month,
      year: year,
      sessions: {},
    });
  }

  // Populate the array of 'month objects' with relevant values
  useEffect(() => {
    // from latest 12 months, assign, for each project, the number of sessions created each month
    const dataIR = lastTwelveMonths.map((monthSexyObject: any) => {
      const sessionsOfThatMonth: Record<string, number> = {};

      sessionsByProjectGroupWithColor.forEach((group) => {
        group.projects.forEach((project: ISessionsByProjectWithColor) => {
          // when there is a userId greater than zero, filter sessions by userId

          const sessions = [...project.sessions].filter((s) => {
            // We don't need to filter by user when there is no userId prop (typically, when we're in creator mode & not enduser mode)
            if (userId !== 0) {
              return s.enduser && Number(s.enduser.id) === Number(userId);
            } else {
              return true;
            }
          });

          sessions.forEach((session: ISession) => {
            const id = project.project_id;
            const monthOfSession = new Date(session.createdAt).getMonth();
            const yearOfSession = new Date(session.createdAt).getFullYear();

            if (
              monthSexyObject.year === yearOfSession &&
              monthSexyObject.month === monthOfSession
            ) {
              sessionsOfThatMonth[id] = (sessionsOfThatMonth[id] || 0) + 1; // Increment the session count for this project for this month
            }
          });
        });
      });

      return {
        ...monthSexyObject,
        sessions: sessionsOfThatMonth,
      };
    });
    setData(dataIR as any);
  }, [sessionsByProjectGroupWithColor]);

  const barData = data.map((sexyMonthObject: any) => {
    /* need objects that have, for each project, a [key] with project.id, that have number of sessions for that month as value */
    const sessions = sexyMonthObject.sessions;
    return {
      ...sessions,
      monthToDisplay: sexyMonthObject.toDisplay,
    };
  });

  const renderBars = () => {
    if (isProject) {
      if (id) {
        return sessionsByProjectGroupWithColor.flatMap(
          (group: ISessionsByProjectGroupWithColor) => {
            return group.projects
              .filter((project: ISessionsByProjectWithColor) => project.project_id === id)
              .map((project: ISessionsByProjectWithColor) => {
                return (
                  <Bar
                    key={`bar-${project.project_id}`}
                    dataKey={project.project_id}
                    name={project.project_name}
                    fill={project.color || "#563CFF"}
                    stackId="a"
                  />
                );
              });
          },
        );
      } else {
        return sessionsByProjectGroupWithColor.flatMap(
          (group: ISessionsByProjectGroupWithColor) => {
            return group.projects.map((project: ISessionsByProjectWithColor) => {
              return (
                <Bar
                  key={`bar-${project.project_id}`}
                  dataKey={project.project_id}
                  name={project.project_name}
                  fill={project.color || "#8884d8"}
                  stackId="a"
                />
              );
            });
          },
        );
      }
    } else {
      if (id) {
        return sessionsByProjectGroupWithColor
          .filter((group: ISessionsByProjectGroup) => group.project_group_id === id)
          .flatMap((group: ISessionsByProjectGroup) => {
            return group.projects.map((project: ISessionsByProjectWithColor) => {
              return (
                <Bar
                  key={`bar-${project.project_id}`}
                  dataKey={project.project_id}
                  name={project.project_name}
                  fill={project.color || "#563CFF"} // Default color if none specified
                  stackId="a"
                />
              );
            });
          });
      } else {
        return sessionsByProjectGroupWithColor.flatMap((group: ISessionsByProjectGroup) => {
          return group.projects.map((project: ISessionsByProjectWithColor) => {
            return (
              <Bar
                key={`bar-${project.project_id}`}
                dataKey={project.project_id}
                name={project.project_name}
                fill={project.color || "#8884d8"} // Default color if none specified
                stackId="a"
              />
            );
          });
        });
      }
    }
  };

  return (
    <ResponsiveContainer id="tata" className="bar-chart">
      {noSession ? (
        <p className="no-data-message">{t("pages.statistics.noData")}</p>
      ) : (
        <BarChart margin={{ top: 20, right: 30, left: 0, bottom: 0 }} data={barData}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="monthToDisplay" interval={0} />
          <YAxis />
          {renderBars()}
          <Tooltip
            position={{ y: 50 }}
            cursor={{ fill: "rgba(0,0,0,0.15)" }}
            labelFormatter={(value) => {
              const parsed = value.split("/");
              const prettyMonth = monthList[parsed[0] - 1];
              const prettyYear = `20${parsed[1]}`;

              return `${prettyMonth} ${prettyYear}`;
            }}
          />
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};

export default BarChartComp;
